import { RotateLoader } from "react-spinners";

const Fallback = () => {
	return (
		<div className="fallback">
			<RotateLoader color={"#4e4e4e"} size={50} margin={50} speedMultiplier={0.65}/>
		</div>
	)
}

export default Fallback;