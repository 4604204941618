import { FC, useState } from "react";
import { ArcherElement } from "react-archer";
import Person from "../model/Person";
import ChildrenBlock from "./ChildrenBlock";

interface Props {
  data: Person;
}

const Descendent: FC<Props> = ({ data: { id, known_as, ...data } }) => {
  const [showChildren, setShowChildren] = useState(false);
  const [showName, setShowName] = useState(true);


  const toggle = () => {
	showChildren ? setTimeout(() => setShowName(true), 1000) : setShowName(false);
    setShowChildren(!showChildren);
  };

  return (
    <div id={id} className={data.children ? "node" : "leaf"}>
      <ArcherElement
        id={`${id}-element`}
        relations={data.children?.map((child) => {
          return {
            targetId: `${child.id}-element`,
            targetAnchor: "top",
            sourceAnchor: "bottom",
          };
        })}
      >
        <div>
          <img
            id={`${id}-portrait`}
            className="portrait"
            src={`${process.env.PUBLIC_URL}/img/portraits/default.png`}
            title={known_as}
            alt={known_as}
            onClick={data.children && toggle}
          />
        </div>
      </ArcherElement>
      {showName && <h2 className="portrait_name">{known_as}</h2>}
      {data.children && (
        <ChildrenBlock data={data.children} show={showChildren} parentId={id} />
      )}
    </div>
  );
};

export default Descendent;
