import Person from "../model/Person";
import Descendent from "./Descendent";
import { ArcherContainer } from "react-archer";

const testPerson: Person = {
  id: "eduardo",
  known_as: "Eduardo",
  name: "Eduardo",
  surname: "Estévez Durán",
  spouse: {
    id: "carmen",
    known_as: "Carmen",
    name: "Carmen",
    surname: "Agrafojo Arestiño",
  },
  children: [
    {
      id: "eduardojeronimo",
      known_as: "Eduardo",
      name: "Eduardo",
      middle_name: "Jerónimo",
      surname: "Estévez Agrafojo",
      children: [
        {
          id: "luis",
          known_as: "Luís",
          name: "Luis Eduardo",
          surname: "Estévez Figueroa",
          children: [
            {
              id: "carolina",
              known_as: "Carolina",
              name: "Carolina",
              surname: "Estévez ?",
            },
          ],
        },
        {
          id: "carme",
          known_as: "Carme",
          name: "Carme",
          surname: "Estévez Figueroa",
        },
      ],
    },
    {
      id: "juanfrancisco",
      known_as: "Juan",
      name: "Juan",
      middle_name: "Francisco",
      surname: "Estévez Agrafojo",
      children: [
        {
          id: "fallou",
          known_as: "David Fallou",
          name: "David",
          middle_name: "Fallou",
          surname: "Estévez ?",
        },
      ],
    },
    {
      id: "rita",
      known_as: "Rita",
      name: "Rita",
      surname: "Estévez Agrafojo",
      children: [
        {
          id: "riki",
          known_as: "Riky",
          name: "Ricardo",
          surname: "Soto Estévez",
        },
        {
          id: "alvarito",
          known_as: "Álvaro",
          name: "Álvaro",
          surname: "Soto Estévez",
        },
      ],
    },
  ],
};

const Tree = () => {
  return (
    <ArcherContainer
      className="arrow-container"
      strokeColor="#4e4b46"
      endMarker={false}
      offset={2}
    >
      <Descendent data={testPerson} />
    </ArcherContainer>
  );
};

export default Tree;
