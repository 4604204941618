import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const debug = process.env.NODE_ENV !== "production";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
	lng: "gl",
    fallbackLng: "gl",
    load: "languageOnly",
    debug: debug,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;