import { FC, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import Person from "../model/Person";
import Descendent from "./Descendent";

interface Props {
  data: Person[];
  show: boolean;
  parentId: string;
}

const ChildrenBlock: FC<Props> = ({ data, show, parentId }) => {
  const nodeRef = useRef(null);

  return (
    <>
      <CSSTransition
        nodeRef={nodeRef}
        in={show}
        timeout={800}
        classNames="child-block"
        mountOnEnter
        unmountOnExit
        appear
      >
        {(_state) => (
          <div ref={nodeRef} className="child-block">
            {data.map((data) => (
              <Descendent key={data.id} data={data} />
            ))}
          </div>
        )}
      </CSSTransition>
    </>
  );
};

export default ChildrenBlock;
