import { Suspense } from "react";
import Fallback from "./components/Fallback";
import Tree from "./components/Tree";

// todo replace fallback
const App = () => {
  return (
    <Suspense fallback={<Fallback />}>
      <Tree />
    </Suspense>
  );
};

export default App;
